<template>
  <div>
    <layoutContainer>
      <div class="check-box">
        <div @click="activeIndex = i" :class="activeIndex == i ? 'active' : ''" v-for="(item, i) in baseData" :key="i">
          {{ item }}
        </div>
      </div>

      <el-row v-if="[0, 1].includes(activeIndex) && companyList.length" :gutter="20">
        <h4>公司</h4>
        <el-col :span="6" v-for="(item, i) in companyList" :key="i">
          <companyListItem :item="item" />
        </el-col>
        <el-col :span="24">
          <el-button class="more-button" type="text" style="float:right" @click="$router.push('/company')">
            查看更多 <span class="more-span">{{ SearchInput }}</span> 公司<i
              class="el-icon-arrow-right el-icon--right"
            ></i>
          </el-button>
        </el-col>
      </el-row>

      <el-row v-if="[0, 2].includes(activeIndex) && teamsList.length" :gutter="20">
        <h4>团队</h4>
        <el-col :span="6" v-for="(item, i) in teamsList" :key="i">
          <teamListItem :item="item" />
        </el-col>
        <el-col :span="24">
          <el-button class="more-button" type="text" style="float:right" @click="$router.push('/teams')">
            查看更多 <span class="more-span">{{ SearchInput }}</span> 团队<i
              class="el-icon-arrow-right el-icon--right"
            ></i>
          </el-button>
        </el-col>
      </el-row>

      <el-row v-if="[0, 3].includes(activeIndex) && personList.length" :gutter="20"
        ><h4>个人</h4>
        <el-col :span="6" v-for="(item, i) in personList" :key="i">
          <persionListItem :item="item" />
        </el-col>
        <el-col :span="24">
          <el-button class="more-button" type="text" style="float:right" @click="$router.push('/personal')">
            查看更多 <span class="more-span">{{ SearchInput }}</span> 个人<i
              class="el-icon-arrow-right el-icon--right"
            ></i>
          </el-button>
        </el-col>
      </el-row>
    </layoutContainer>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {searchApi} from '../api/standard'

export default {
  data() {
    return {
      activeIndex: 0,
      baseData: ['全部', '公司', '团队', '个人'],
      dataList: {},
      companyList: [],
      teamsList: [],
      personList: []
    }
  },
  computed: {
    ...mapState(['SearchInput'])
  },
  watch: {
    SearchInput() {
      this.search()
    },
    '$route.query.SearchInput'() {
      this.search(this.$route.query.SearchInput)
    }
  },
  created() {
    const SearchInput = this.$route.query.SearchInput || this.SearchInput
    this.search(SearchInput)
  },
  methods: {
    search(SearchInput) {
      searchApi({
        keyWord: SearchInput || this.SearchInput,
        page: 1,
        size: 8
      }).then(res => {
        console.log('%c 搜索结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.dataList = res.data
        this.companyList = res.data?.companyVoPage?.records || []
        this.teamsList = res.data?.teamVoPage?.records || []
        this.personList = res.data?.employeeVoPage?.records || []
      })
    }
  }
}
</script>

<style scoped lang="scss">
.check-box {
  display: flex;
  height: 70px;
  background: #ffffff;
  > div {
    width: 80px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 4px;
    margin: 20px;
  }
}
.active {
  color: #ffffff;
  background: #5ad3cd;
}

h4 {
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
  padding-left: 10px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.more-button {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #007aff;
}
.more-span {
  color: #ff724c;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
</style>
